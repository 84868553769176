import React from "react";

import Layout from "../components/layout";
import Section from "../components/section";
import Heading from "../components/heading";
import SEO from "../components/seo";
import Logos from "../components/logos";
import Subheading from "../components/subheading";
import supporters from "../styles/supporters.module.css";

import google from "../assets/images/press_logos/Google_2015_logo.svg";
import aws from "../assets/images/press_logos/Amazon_Web_Services_Logo.svg";
import doxim from "../assets/images/press_logos/Doximity.svg";
import urisa from "../assets/images/press_logos/urisa_logo_blue.png";
import fema from "../assets/images/press_logos/FEMA_logo.svg";

const logos = [
  [google, "Google"],
  [aws, "Amazon Web Services"],
  [doxim, "Doximity"],
  [urisa, "The Urban and Regional Information Systems Association (URISA)"],
  [fema, "FEMA"]
];

export default function IndexPage() {
  return (
    <Layout
      hero={
        <Section>
          <Heading>Our Supporters</Heading>
        </Section>
      }
    >
      <SEO title="Our Supporters" />
      <Section>
        <div className={supporters.inner}>
          <Subheading className={supporters.heading}>
            We are grateful to the following organizations for contributing
            data, technical resources, guidance, and encouragement.
          </Subheading>

          <Logos logoData={logos} color />
        </div>
      </Section>
    </Layout>
  );
}
